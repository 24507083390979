import React, { useState } from 'react';
import Layout from '../components/Layout';
import axios from 'axios';
const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

const IndexPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);

  const onSend = async () => {
    try {
      setSent(true);
      const res = await axios.post(`${API_URL}/contact`, {
        name,
        email,
        subject,
        message,
      });
    } catch (err) {
      console.log(err);
      setSent(true);
      setError(true);
    }
  };

  return (
    <Layout fullMenu>
      <article id="main">
        <header>
          <h2>Contact Information</h2>
        </header>
        <section className="wrapper style5">
          <div className="inner">
            {sent ? (
              error ? (
                <p style={{ color: 'red' }}>
                  Unfortunately we could not process your request, please try
                  again or contact us via email/phone
                </p>
              ) : (
                <p>Thank you for contacting us, you will hear back shortly!</p>
              )
            ) : (
              <form>
                <label>
                  Name
                  <input
                    type="text"
                    name="name"
                    id="name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </label>
                <label>
                  Email
                  <input
                    type="email"
                    name="email"
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </label>
                <label>
                  Subject
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </label>
                <label>
                  Message
                  <textarea
                    name="message"
                    id="message"
                    rows="5"
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </label>
                <button type="button" onClick={onSend}>
                  Send
                </button>
                <input type="reset" value="Clear" />
              </form>
            )}
            <div className="card">
              <h6>e-mail:</h6>
              <p>support@advancece.org</p>
            </div>
            {/* <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11961.481613285609!2d-87.32072!3d41.452881!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc4242b56b40fa2f!2sLinear%20Solutions%20Inc!5e0!3m2!1sen!2sus!4v1612731379438!5m2!1sen!2sus"
                width="600"
                height="450"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div> */}
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default IndexPage;
